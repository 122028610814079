import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
import { applicationStatus, sessions } from 'src/util/helpers';
import moment from 'moment';
import { getAllBatches } from 'src/axiosLib/services/batch/admin';
import { getAllBranches } from 'src/axiosLib/services/branch/admin';
import {
  createNewCourseEnrollmentAdmin,
  getEnrolledUsersInCourse,
  getSingleCoursesAdmin,
} from '../../axiosLib/services/course/admin';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { getAllExamsForCourse } from '../../axiosLib/services/exam/admin';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'roll', label: 'Roll', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  // { id: 'session', label: 'Session', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  { id: 'guardianSMS', label: 'Guardian SMS', alignRight: false },
  { id: 'valid_till', label: 'Validity', alignRight: false },
  { id: 'isValid', label: 'Is Valid', alignRight: false },
  { id: 'profile', label: 'View Profile', alignRight: false },
];

const TABLE_HEAD_EXAM = [
  { id: 'name', label: 'Exam Name', alignRight: false },
  { id: 'code', label: 'Exam Code', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'enrolled', label: 'Participants', alignRight: false },

  { id: 'marks', label: 'Marks', alignRight: false },
  { id: 'Start Time', label: 'Start Time', alignRight: false },
  { id: 'End Time', label: 'End Time', alignRight: false },
  // { id: 'registered_at', label: 'Registered at', alignRight: false },
  { id: 'profile', label: 'EDIT', alignRight: false },
];

// ----------------------------------------------------------------------

export default function CourseDetailsAndEnrollments() {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);

  const [enrollmentModal, setEnrollmentModal] = useState(false);

  const [sessionField, setSessionField] = useState([]);

  const [enrollmentType, setEnrollmentType] = useState('Admitted');

  const [order, setOrder] = useState('asc');

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [userList, setUserList] = useState([]);

  const [userCount, setUserCount] = useState(0);

  const [examCount, setExamCount] = useState(0);

  const [batchList, setBatchList] = useState([]);

  const [examList, setExamList] = useState([]);

  const [branchList, setBranchList] = useState([]);

  const [branchField, setBranchField] = useState('');

  const [newEnrollmentPhone, setNewEnrollmentPhone] = useState('');

  const [batchField, setBatchField] = useState('');

  const [courseDetails, setCourseDetails] = useState({});

  const [pageExam, setPageExam] = useState(0);

  const [rowsPerPageExam, setRowsPerPageExam] = useState(10);

  const [serverDate, setServerDate] = useState(new Date());

  const handleChangePageExam = (event, newPage) => {
    setPageExam(newPage);
  };

  const handleChangeRowsPerPageExam = (event) => {
    setRowsPerPageExam(event.target.value);
    setPageExam(0);
  };

  const getExams = () => {
    try {
      getAllExamsForCourse(rowsPerPageExam, pageExam, getCourseIdFromUrl(), (error, data) => {
        if (!error) {
          setExamList(get(data, 'exams', []));
          setExamCount(get(data, 'size', 0));
          setServerDate(new Date(get(data, 'serverTime', new Date())));
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getCourseIdFromUrl = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get('courseId');
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getBatches = () => {
    try {
      getAllBatches(1000, 0, (error, data) => {
        if (!error) {
          // console.log(data);
          setBatchList(get(data, 'batches', []));
        } else {
          console.log(error);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getBranches = () => {
    try {
      getAllBranches(1000, 0, (error, data) => {
        if (!error) {
          // console.log(data);
          setBranchList(get(data, 'branches', []));
        } else {
          console.log(error);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getCourseDetails = () => {
    try {
      getSingleCoursesAdmin(getCourseIdFromUrl(), (error, data) => {
        if (!error) {
          setCourseDetails(get(data, 'course', {}));
        } else {
          console.log(error);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setRowsPerPage(10);
    setFilterName(event.target.value);
  };

  const handleNewEnrollmentChange = (fieldName, event) => {
    // console.log(event.target.value);
    setNewEnrollmentPhone({ ...newEnrollmentPhone, [fieldName]: event.target.value });
  };

  const handleNewEnrollmentEdit = (row) => {
    setNewEnrollmentPhone(row);

    setEnrollmentModal(true);
  };

  const createNewEnrollment = () => {
    console.log(newEnrollmentPhone)

    createNewCourseEnrollmentAdmin(
      newEnrollmentPhone.phone,
      newEnrollmentPhone.status,
      getCourseIdFromUrl(),
      (error, data) => {
        if (!error) {
          // console.log(data);

          alert('Successful');

          window.location.reload(false);
        } else {
          alert('This student is not registered yet. Please Register student first.');
        }
      }
    );

    // setSubjectModal(false)
  };

  const getUsers = (rowsPerPage, page) => {
    try {
      getEnrolledUsersInCourse(
        getCourseIdFromUrl(),
        filterName,
        rowsPerPage,
        page,
        branchField,
        batchField,
        sessionField,
        enrollmentType,

        (error, data) => {
          if (!error) {
            setUserList(get(data, 'user', []));
            setUserCount(get(data, 'total', 0));
          } else {
            console.log(error);
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleBatchFilter = (e) => {
    setBatchField(e.target.value);
    // getUsers(rowsPerPage , page, batchField, branchField);
  };

  const handleBranchFilter = (e) => {
    setBranchField(e.target.value);
  };

  const handleSessionFilter = (e) => {
    setSessionField(e.target.value);
  };

  const handleEnrollmentTypeFilter = (e) => {
    setEnrollmentType(e.target.value);
  };

  const isUserNotFound = userList.length === 0;

  useEffect(() => {
    getExams();
  }, [pageExam, rowsPerPageExam]);

  useEffect(() => {
    getUsers(rowsPerPage, page);
  }, [rowsPerPage, page, batchField, branchField, sessionField, enrollmentType]);

  useEffect(() => {
    getBatches();
    getBranches();
    getCourseDetails();
  }, []);

  useEffect(() => {
    if (filterName !== null && filterName !== '') {
      getUsers(rowsPerPage, page, batchField, branchField);
    }
  }, [filterName, page, rowsPerPage, batchField, branchField, enrollmentType]);

  return (
    <Page title="User">
      <Container>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <Card sx={{ width: '98%', marginBottom: '30px', border: '2px solid #800000' }}>
            <CardContent style={{ backgroundColor: 'beige', color: '#800000', fontWeight: 'bold', fontSize: '20px' }}>
              <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <Typography variant="h4">{`Course Title: ${get(courseDetails, 'title', '')}`}</Typography>
                <Typography variant="h5">{`Course Duration: ${get(courseDetails, 'time', '')}`}</Typography>
                <Typography variant="h5">{`Course Price before discount: ${get(
                  courseDetails,
                  'price',
                  ''
                )} BDT`}</Typography>
                <Typography variant="h5">{`Course Price after discount: ${get(
                  courseDetails,
                  'discountedPrice',
                  ''
                )} BDT`}</Typography>
                <Typography variant="h5">{`Course Teachers: ${get(courseDetails, 'teachers', '')}`}</Typography>
                <Typography variant="h6">{`Course Details: ${get(courseDetails, 'description', '')}`}</Typography>
              </div>
            </CardContent>
          </Card>
        </div>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
          <Typography variant="h4" gutterBottom>
            Enrolled Student List in this course
          </Typography>
          <Button
            variant="contained"
            onClick={() => handleNewEnrollmentEdit({ status: 'Admitted' })}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Enroll New Student To This Course
          </Button>
        </Stack>

        <Card>
          <Stack direction="row" alignItems="center" justifyContent="space-around" spacing={5}>
            <UserListToolbar filterName={filterName} onFilterName={handleFilterByName} />
            <FormControl fullWidth required style={{ width: '30%' }}>
              <InputLabel id="sessiom-label">Enrollment Status</InputLabel>
              <Select
                id="enrollment-status-label"
                defaultValue={enrollmentType}
                label="Enrollment Status"
                onChange={(e) => {
                  handleEnrollmentTypeFilter(e);
                }}
              >
                {applicationStatus.map((option) => (
                  <MenuItem value={option} key={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth required style={{ width: '30%' }}>
              <InputLabel id="sessiom-label">Session</InputLabel>
              <Select
                id="session-label"
                defaultValue={''}
                label="Session"
                onChange={(e) => {
                  handleSessionFilter(e);
                }}
              >
                {sessions.map((option) => (
                  <MenuItem value={option} key={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth required style={{ width: '50%' }}>
              <InputLabel id="sessiom-label">Branch Name</InputLabel>
              <Select
                id="branch-label"
                defaultValue={''}
                label="Batch Name"
                onChange={(e) => {
                  handleBranchFilter(e);
                }}
              >
                {branchList.map((batch) => (
                  <MenuItem key={batch.branchName} value={batch._id}>
                    {batch.branchName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth required style={{ width: '50%' }}>
              <InputLabel id="sessiom-label">Batch Name</InputLabel>
              <Select id="session-label" defaultValue={''} label="Batch Name" onChange={(e) => handleBatchFilter(e)}>
                {batchList.map((batch) => (
                  <MenuItem key={batch.batchName} value={batch._id}>
                    {batch.batchName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={userList.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {userList.map((row, i) => {
                    return (
                      <TableRow hover key={get(row, '_id', '')} tabIndex={-1}>
                        <TableCell align="left"> {get(row, 'roll', '')}</TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={get(row, 'username', '')} src={get(row, 'avatar', '')} />
                            <Typography variant="subtitle2" noWrap>
                              {get(row, 'username', '')}
                            </Typography>
                          </Stack>
                        </TableCell>
                        {/* <TableCell align="left"> {get(row,'session' , '')}</TableCell> */}
                        <TableCell align="left"> {get(row, 'phone', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'guardianSMS', '')}</TableCell>
                        <TableCell align="left"> {moment(get(row, 'validTill')).format('DD/MM/YYYY')}</TableCell>

                        <TableCell align="left">
                          {get(row, 'isVerified') == false ? (
                            <Button
                              variant="contained"
                              style={{
                                background: 'red',
                                color: '#fff',
                              }}
                            >
                              INVALID
                            </Button>
                          ) : new Date(get(row, 'validTill')) < new Date() ? (
                            <Button
                              variant="contained"
                              style={{
                                background: 'red',
                                color: '#fff',
                              }}
                            >
                              EXPIRED
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              style={{
                                background: 'green',
                                color: '#fff',
                              }}
                            >
                              VALID
                            </Button>
                          )}
                        </TableCell>

                        <TableCell align="left">
                          {' '}
                          {
                            <Button
                              variant="contained"
                              component={RouterLink}
                              to={'/dashboard/register_student/?std=' + get(row, '_id')}
                            >
                              VIEW
                            </Button>
                          }
                        </TableCell>

                        <TableCell align="left">
                          {enrollmentType !== 'Admitted' ? (
                            <Button
                              variant="contained"
                              onClick={() => {
                                row.status = 'Admitted';
                                handleNewEnrollmentEdit(row)
                              }}
                              style={{
                                background: 'green',
                                color: '#fff',
                              }}
                            >
                              GRANT APPROVAL
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              onClick={() => {
                                row.status = 'Declined';
                                handleNewEnrollmentEdit(row)
                              }}
                              style={{
                                background: 'red',
                                color: '#fff',
                              }}
                            >
                              REMOVE FROM COURSE
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>

                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={userCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={10}>
          {' '}
        </Stack>

        <Card>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
            <Typography
              variant="h4"
              gutterBottom
              style={{ marginLeft: 15, color: '#FF0000', backgroundColor: 'white' }}
            >
              Exam List In This Course
            </Typography>
            <Button
              style={{ marginRight: 10 }}
              variant="contained"
              component={RouterLink}
              to="/dashboard/examDetails"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Create New Exam
            </Button>
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead headLabel={TABLE_HEAD_EXAM} rowCount={examList.length} />
                <TableBody>
                  {examList.map((row) => {
                    return (
                      <TableRow hover key={get(row, '_id', '')} tabIndex={-1}>
                        <TableCell align="left"> {get(row, 'name', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'code', '')}</TableCell>

                        <TableCell align="left">
                          {new Date(row.startTime) >= serverDate ? (
                            <Button variant="contained" color="primary" onClick={() => {}}>
                              Upcoming
                            </Button>
                          ) : new Date(row.answerPublishTime).getTime() <= serverDate ? (
                            <Button
                              variant="contained"
                              style={{
                                background: 'red',
                                color: '#fff',
                              }}
                            >
                              Finished
                            </Button>
                          ) : new Date(row.endTime) <= serverDate ? (
                            <Button
                              variant="contained"
                              style={{
                                background: 'red',
                                color: '#fff',
                              }}
                            >
                              Finished
                            </Button>
                          ) : (
                            <Button variant="contained" style={{ background: '#194e39', color: '#fff' }}>
                              Running
                            </Button>
                          )}
                        </TableCell>

                        <TableCell align="left"> {row.enrolled}</TableCell>
                        <TableCell align="left"> {row.totalMarks}</TableCell>

                        <TableCell align="left">
                          {' '}
                          {moment(get(row, 'startTime')).format('( DD-MM-yyyy )-( hh:mm A )')}
                        </TableCell>
                        <TableCell align="left">
                          {' '}
                          {moment(get(row, 'endTime')).format('( DD-MM-yyyy )-( hh:mm A )')}
                        </TableCell>

                        <TableCell align="left">
                          <Button
                            variant="contained"
                            component={RouterLink}
                            to={'/dashboard/editExam/?examId=' + get(row, '_id')}
                          >
                            EDIT
                          </Button>
                        </TableCell>

                        <TableCell align="left">
                          <Button
                            variant="contained"
                            component={RouterLink}
                            to={'/dashboard/examResult/?examId=' + get(row, '_id')}
                          >
                            VIEW
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={examCount}
            rowsPerPage={rowsPerPageExam}
            page={pageExam}
            onPageChange={handleChangePageExam}
            onRowsPerPageChange={handleChangeRowsPerPageExam}
          />
        </Card>

        {/* SUBJECT MODAL*/}
        <div>
          <Dialog open={enrollmentModal} onClose={() => setEnrollmentModal(false)}>
            <DialogTitle>Enroll/Unroll Student</DialogTitle>

            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <Card sx={{ width: '90%', marginBottom: '30px', border: '2px solid #800000' }}>
                <CardContent
                  style={{ backgroundColor: 'beige', color: '#800000', fontWeight: 'bold', fontSize: '20px' }}>
                  <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                    <Typography variant="h6">{`Course: ${get(courseDetails, 'title', '')}`}</Typography>
                    <Typography variant="h6">{`Name: ${get(newEnrollmentPhone, 'username', '- - - -')}`}</Typography>
                    <Typography variant="h6">{`Phone: ${get(newEnrollmentPhone, 'phone', '- - - -')}`}</Typography>
                    <Typography variant="h6">{`Guardian Phone: ${get(newEnrollmentPhone, 'guardianSMS', '- - - -')}`}</Typography>
                  </div>
                </CardContent>
              </Card>
            </div>

            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
              {' '}
            </Stack>
            <DialogContent>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  name="phone"
                  label="Student Phone Number"
                  style={{ width: '100%' }}
                  value={get(newEnrollmentPhone, 'phone', '')}
                  onChange={(e) => {
                    handleNewEnrollmentChange('phone', e);
                  }}
                  required
                />
              </Stack>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} paddingTop={2}>
                <FormControl fullWidth required style={{ width: '75%' }}>
                  <InputLabel id="sessiom-label">New Status</InputLabel>
                  <Select
                    id="enrollment-status-label"
                    value={get(newEnrollmentPhone, 'status', '')}
                    label="Enrollment Status"
                    onChange={(e) => {
                      handleNewEnrollmentChange('status', e);
                    }}
                  >
                    {applicationStatus.map((option) => (
                      <MenuItem value={option} key={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </DialogContent>

            <DialogActions>
              <Button
                variant="contained"
                onClick={createNewEnrollment}
                style={{ color: 'white', backgroundColor: 'blue' }}
              >
                Enroll/Unroll Student
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Container>
    </Page>
  );
}
