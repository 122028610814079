import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

// APIS
// material
import {
  Button,
  Card,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { UserListHead } from '../../sections/@dashboard/user';
import { paymentMonth, paymentTypes, sessions } from 'src/util/helpers';
import { getMonthlyDueStudentList, getOneTimeDueStudentList } from 'src/axiosLib/services/payment/admin';
import { getAllCoursesAdmin } from '../../axiosLib/services/course/admin';

// ----------------------------------------------------------------------

const TABLE_HEAD_BATCH = [
  { id: 'amount', label: 'User name', alignRight: false },
  { id: 'Payment Method', label: 'Roll', alignRight: false },
  { id: 'username', label: 'Student Phone', alignRight: false },
  { id: 'phone', label: 'Guardian Phone', alignRight: false },
  { id: 'roll', label: 'Course Name', alignRight: false },
  { id: 'date', label: 'Date', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
];

const TABLE_HEAD = [
  { id: 'roll', label: 'Roll', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  // { id: 'session', label: 'Session', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  { id: 'guardianSMS', label: 'Guardian SMS', alignRight: false },
  { id: 'courseFee', label: 'Course Fee', alignRight: false },
  { id: 'payment collected', label: 'Payment Collected', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'profile', label: 'View Profile', alignRight: false },
];
// ----------------------------------------------------------------------

export default function DuePayment() {
  const navigate = useNavigate();

  const [courseList, setCourseList] = useState([]);

  const [monthlyDueList, setMonthlyDueList] = useState([]);
  const [monthlyDueCount, setMonthlyDueCount] = useState(0);
  const [monthlyDuePage, setMonthlyDuePage] = useState(0);
  const [monthlyDueRowsPerPage, setMonthlyDueRowsPerPage] = useState(10);

  const [oneTimeDueList, setOneTimeDueList] = useState([]);
  const [oneTimeDueCount, setOneTimeDueCount] = useState(0);
  const [oneTimeDuePage, setOneTimeDuePage] = useState(0);
  const [oneTimeDueRowsPerPage, setOneTimeDueRowsPerPage] = useState(10);

  const [monthlyDueSearchCmd, setMonthlyDueSearchCmd] = useState({
    year: new Date().toLocaleString('en-US', { year: 'numeric' }),
    collectionForMonth: paymentMonth[new Date().getMonth()],
  });

  const [oneTimeDueSearchCmd, setOneTimeDueSearchCmd] = useState({
    year: new Date().toLocaleString('en-US', { year: 'numeric' }),
  });

  const handleMonthlyDueSearchCmdChange = (fieldName, event) => {
    setMonthlyDueSearchCmd({ ...monthlyDueSearchCmd, [fieldName]: event.target.value });
  };

  const handleOneTimeDueSearchCmdChange = (fieldName, event) => {
    setOneTimeDueSearchCmd({ ...oneTimeDueSearchCmd, [fieldName]: event.target.value });
  };

  const handleMonthlyDuePageChange = (event, newPage) => {
    setMonthlyDuePage(newPage);
  };

  const handleMonthlyDueRowsPerPageChange = (event) => {
    setMonthlyDueRowsPerPage(event.target.value);
    setMonthlyDuePage(0);
  };

  const handleOneTimePageChange = (event, newPage) => {
    setOneTimeDuePage(newPage);
  };

  const handleOneTimeDueRowsPerPageChange = (event) => {
    setOneTimeDueRowsPerPage(event.target.value);
    setOneTimeDuePage(0);
  };

  const getMonthlyDuesOfStudents = () => {
    const tempObj = {
      year: monthlyDueSearchCmd.year,
      collectionForMonth: monthlyDueSearchCmd.collectionForMonth,
      courseId: monthlyDueSearchCmd.courseId,
      limit: monthlyDueRowsPerPage,
      page: monthlyDuePage,
    };

    getMonthlyDueStudentList(tempObj, (error, data) => {
      if (!error) {
        // console.log(data);
        setMonthlyDueList(get(data, 'userWhoDidNotPaid', []));
        setMonthlyDueCount(get(data, 'totalUsers', 0));
      } else {
      }
    });
  };

  const getOneTimeDuesOfStudents = () => {
    const tempObj = {
      year: oneTimeDueSearchCmd.year,
      courseId: oneTimeDueSearchCmd.courseId,
      limit: oneTimeDueRowsPerPage,
      page: oneTimeDuePage,
    };

    getOneTimeDueStudentList(tempObj, (error, data) => {
      if (!error) {
        // console.log(data);
        setOneTimeDueList(get(data, 'userWhoDidNotPaid', []));
        setOneTimeDueCount(get(data, 'totalUsers', 0));
      } else {
      }
    });
  };

  useEffect(() => {
    getMonthlyDuesOfStudents();
  }, [monthlyDuePage, monthlyDueRowsPerPage]);

  useEffect(() => {
    getOneTimeDuesOfStudents();
  }, [oneTimeDuePage, oneTimeDueRowsPerPage]);

  useEffect(() => {
    getAllCoursesAdmin((error, data) => {
      if (!error) {
        console.log(data);
        setCourseList(get(data, 'courses', []));
      } else {
        console.log(error);
      }
    });
  }, []);

  return (
    <Page title="Revenue Track">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
          <Typography variant="h4" gutterBottom>
            One-Time Course Dues
          </Typography>
          {/* <Button variant="contained" component={RouterLink} to="/dashboard/register_student"
          startIcon={<Iconify icon="eva:plus-fill"/>}>
            Add New Student
          </Button> */}
        </Stack>

        <Card>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
            {' '}
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="space-around" spacing={5}>
            {/*<FormControl fullWidth required style={{ width: '30%', marginLeft: 15 }}>*/}
            {/*  <InputLabel id="sessiom-label">Session/Year</InputLabel>*/}
            {/*  <Select*/}
            {/*    id="session-label"*/}
            {/*    defaultValue={get(oneTimeDueSearchCmd, 'year', '')}*/}
            {/*    label="Session"*/}
            {/*    onChange={(e) => {*/}
            {/*      handleOneTimeDueSearchCmdChange('year', e);*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    {sessions.map((option) => (*/}
            {/*      <MenuItem value={option} key={option}>*/}
            {/*        {option}*/}
            {/*      </MenuItem>*/}
            {/*    ))}*/}
            {/*  </Select>*/}
            {/*</FormControl>*/}

            <FormControl fullWidth required style={{ width: '100%', marginLeft: '15px' }}>
              <InputLabel id="course-label-one-time">Payment for the course</InputLabel>
              <Select
                // labelId="session"
                id="course-label-one-time"
                value={get(oneTimeDueSearchCmd, 'courseId', '')}
                label="Course"
                onChange={(e) => {
                  handleOneTimeDueSearchCmdChange('courseId', e);
                }}
              >
                {courseList
                  ?.filter((course) => course?.paymentType === paymentTypes[0])
                  .map((course) => (
                    <MenuItem key={course.title} value={course._id}>
                      {course.title}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <Button
              variant="contained"
              onClick={(e) => {
                getOneTimeDuesOfStudents();
                // getPaymentSummary(paymentReqBody);
              }}
              style={{ color: 'white', backgroundColor: 'blue', width: '20%', marginRight: 15 }}
            >
              {/* <Downloading style={{color: "white", marginRight: 5}}/> */}
              FILTER
            </Button>

            {/*<Button*/}
            {/*  variant="contained"*/}
            {/*  onClick={getAllPaymentsOfStudents}*/}
            {/*  style={{ color: 'white', backgroundColor: 'blue', width: '30%', marginRight: 15 }}*/}
            {/*>*/}
            {/*  <Downloading style={{ color: 'white', marginRight: 5 }} />*/}
            {/*  Download Excel*/}
            {/*</Button>*/}
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  headLabel={TABLE_HEAD_BATCH}
                  rowCount={oneTimeDueList?.length}
                  // onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {oneTimeDueList &&
                    oneTimeDueList.map((row) => {
                      return (
                        <TableRow hover key={get(row, '_id', '')} tabIndex={-1}>
                          <TableCell align="left"> {get(row, 'userName', '')}</TableCell>
                          <TableCell align="left"> {get(row, 'roll', '')}</TableCell>
                          <TableCell align="left"> {get(row, 'phone', '')}</TableCell>
                          <TableCell align="left"> {get(row, 'guardianSMS', '')}</TableCell>
                          <TableCell align="left"> {get(row, 'courseName', '')}</TableCell>
                          <TableCell align="left"> {get(row, 'paymentType', '')}</TableCell>
                          <TableCell align="left">
                            <Button
                              variant="contained"
                              style={{
                                background: 'red',
                                color: '#fff',
                              }}
                            >
                              DUE {get(row, 'due', 0)}
                            </Button>
                          </TableCell>

                          <TableCell align="left">
                            {' '}
                            {
                              <Button
                                variant="contained"
                                component={RouterLink}
                                to={'/dashboard/register_student/?std=' + get(row, 'userId', '')}
                              >
                                VIEW STUDENT
                              </Button>
                            }
                          </TableCell>

                          {/*{get(row, 'paymentArchive', [])[0].length > 0 ? (*/}
                          {/*  <TableCell align="left">*/}
                          {/*    {' '}*/}
                          {/*    {*/}
                          {/*      <Button*/}
                          {/*        variant="contained"*/}
                          {/*        style={{*/}
                          {/*          color: '#FFFFFF',*/}
                          {/*          backgroundColor: '#880ED4',*/}
                          {/*        }}*/}
                          {/*      >*/}
                          {/*        {get(row, 'paymentArchive', []).length}*/}
                          {/*      </Button>*/}
                          {/*    }*/}
                          {/*  </TableCell>*/}
                          {/*) : (*/}
                          {/*  console.log('N/A')*/}
                          {/*)}*/}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 25, 100, 200]}
            component="div"
            count={oneTimeDueCount}
            rowsPerPage={oneTimeDueRowsPerPage}
            page={oneTimeDuePage}
            onPageChange={handleOneTimePageChange}
            onRowsPerPageChange={handleOneTimeDueRowsPerPageChange}
          />
        </Card>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4} style={{ marginTop: '20px' }}>
          <Typography variant="h4" gutterBottom>
            Monthly Course Dues
          </Typography>
          {/* <Button variant="contained" component={RouterLink} to="/dashboard/register_student"
          startIcon={<Iconify icon="eva:plus-fill"/>}>
            Add New Student
          </Button> */}
        </Stack>

        <Card>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
            {' '}
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="space-around" spacing={5}>
            {/*<FormControl fullWidth required style={{ width: '30%', marginLeft: 15 }}>*/}
            {/*  <InputLabel id="sessiom-label">Session/Year</InputLabel>*/}
            {/*  <Select*/}
            {/*    id="session-label"*/}
            {/*    defaultValue={get(monthlyDueSearchCmd, 'year', '')}*/}
            {/*    label="Session"*/}
            {/*    onChange={(e) => {*/}
            {/*      handleMonthlyDueSearchCmdChange('year', e);*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    {sessions.map((option) => (*/}
            {/*      <MenuItem value={option} key={option}>*/}
            {/*        {option}*/}
            {/*      </MenuItem>*/}
            {/*    ))}*/}
            {/*  </Select>*/}
            {/*</FormControl>*/}

            {/*<FormControl fullWidth required style={{ width: '30%', marginLeft: 15 }}>*/}
            {/*  <InputLabel id="sessiom-label">Month</InputLabel>*/}
            {/*  <Select*/}
            {/*    id="month-label"*/}
            {/*    defaultValue={get(monthlyDueSearchCmd, 'collectionForMonth', '')}*/}
            {/*    label="Session"*/}
            {/*    onChange={(e) => {*/}
            {/*      handleMonthlyDueSearchCmdChange('collectionForMonth', e);*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    {paymentMonth.map((option) => (*/}
            {/*      <MenuItem value={option} key={option}>*/}
            {/*        {option}*/}
            {/*      </MenuItem>*/}
            {/*    ))}*/}
            {/*  </Select>*/}
            {/*</FormControl>*/}

            <FormControl fullWidth required style={{ width: '100%', marginLeft: '15px' }}>
              <InputLabel id="course-label">Payment for the course</InputLabel>
              <Select
                // labelId="session"
                id="course-label"
                value={get(monthlyDueSearchCmd, 'courseId', '')}
                label="Course"
                onChange={(e) => {
                  handleMonthlyDueSearchCmdChange('courseId', e);
                }}
              >
                {courseList
                  .filter((course) => course?.paymentType === paymentTypes[1])
                  .map((course) => (
                    <MenuItem key={course.title} value={course._id}>
                      {course.title}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <Button
              variant="contained"
              onClick={(e) => {
                getMonthlyDuesOfStudents();
                // getPaymentSummary(paymentReqBody);
              }}
              style={{ color: 'white', backgroundColor: 'blue', width: '20%', marginRight: 15 }}
            >
              {/* <Downloading style={{color: "white", marginRight: 5}}/> */}
              FILTER
            </Button>

            {/*<Button*/}
            {/*  variant="contained"*/}
            {/*  onClick={getAllPaymentsOfStudents}*/}
            {/*  style={{ color: 'white', backgroundColor: 'blue', width: '30%', marginRight: 15 }}*/}
            {/*>*/}
            {/*  <Downloading style={{ color: 'white', marginRight: 5 }} />*/}
            {/*  Download Excel*/}
            {/*</Button>*/}
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  headLabel={TABLE_HEAD_BATCH}
                  rowCount={monthlyDueList ? monthlyDueList.length : 0}
                  // onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {monthlyDueList &&
                    monthlyDueList.map((row) => {
                      return (
                        <TableRow hover key={get(row, '_id', '')} tabIndex={-1}>
                          <TableCell align="left"> {get(row, 'userName', '')}</TableCell>
                          <TableCell align="left"> {get(row, 'roll', '')}</TableCell>
                          <TableCell align="left"> {get(row, 'phone', '')}</TableCell>
                          <TableCell align="left"> {get(row, 'guardianSMS', '')}</TableCell>
                          <TableCell align="left"> {get(row, 'courseName', '')}</TableCell>
                          <TableCell align="left"> {get(row, 'paymentType', '')}</TableCell>
                          <TableCell align="left">
                            <Button
                              variant="contained"
                              style={{
                                background: 'red',
                                color: '#fff',
                              }}
                            >
                              DUE {get(row, 'due', 0)}
                            </Button>
                          </TableCell>

                          <TableCell align="left">
                            {' '}
                            {
                              <Button
                                variant="contained"
                                component={RouterLink}
                                to={'/dashboard/register_student/?std=' + get(row, 'userId', '')}
                              >
                                VIEW STUDENT
                              </Button>
                            }
                          </TableCell>

                          {/*{get(row, 'paymentArchive', [])[0].length > 0 ? (*/}
                          {/*  <TableCell align="left">*/}
                          {/*    {' '}*/}
                          {/*    {*/}
                          {/*      <Button*/}
                          {/*        variant="contained"*/}
                          {/*        style={{*/}
                          {/*          color: '#FFFFFF',*/}
                          {/*          backgroundColor: '#880ED4',*/}
                          {/*        }}*/}
                          {/*      >*/}
                          {/*        {get(row, 'paymentArchive', []).length}*/}
                          {/*      </Button>*/}
                          {/*    }*/}
                          {/*  </TableCell>*/}
                          {/*) : (*/}
                          {/*  console.log('N/A')*/}
                          {/*)}*/}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 25, 100, 200]}
            component="div"
            count={monthlyDueCount}
            rowsPerPage={monthlyDueRowsPerPage}
            page={monthlyDuePage}
            onPageChange={handleMonthlyDuePageChange}
            onRowsPerPageChange={handleMonthlyDueRowsPerPageChange}
          />
        </Card>
      </Container>
    </Page>
  );
}
