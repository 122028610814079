import React, { useEffect, useState } from 'react';
// import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

import config from 'src/util/config';
import { useNavigate } from 'react-router-dom';
import { printInvoice } from 'src/axiosLib/services/payment/admin';
import { get } from 'lodash';
import moment from 'moment';
import QRCode from 'react-qr-code';

export default function Invoice() {
  const navigate = useNavigate();

  const [invoice, setInvoice] = useState({});
  const [due, setDue] = useState(0);

  const [publicLink, setPublicLink] = useState('');

  const [address, setAddress] = useState([]);

  const [institute, setInstitute] = useState({});

  const [student, setStudentDetails] = useState({});

  const getPaymentInfo = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const invoiceId = urlParams.get('invoiceId');

    try {
      printInvoice(invoiceId, (error, data) => {
        if (!error) {
          console.log(data);
          setInvoice(get(data, 'payment', {}));
          setDue(get(data, 'due', 0));

          setInstitute(get(data, 'institute', {}));
          setStudentDetails(get(data, 'payment.userId', {}));

          setAddress(get(data, 'address', []));
          setPublicLink(get(data, 'link', ''));
        } else {
          console.log(error);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPaymentInfo();
  }, []);

  return (
    <div>
      <meta charSet="utf-8" />
      <title>Exam System Invoice</title>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link href="https://cdn.jsdelivr.net/npm/bootstrap@4.1.1/dist/css/bootstrap.min.css" rel="stylesheet" />

      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html: `
      body {
        margin-top: 20px;
        background: rgb(197, 221, 255);
      }

      .invoice {
        background: #fff;
        padding: 20px;
      }

      .invoice-company {
        font-size: 20px;
      }

      .invoice-header {
        background: #f0f3f4;
        padding: 20px;
      }

      .invoice-date,
      .invoice-from,
      .invoice-to {
        display: table-cell;
        width: 1%;
      }

      .invoice-from,
      .invoice-to {
        padding-right: 20px;
      }

      .invoice-date .date,
      .invoice-from strong,
      .invoice-to strong {
        font-size: 16px;
        font-weight: 600;
      }

      .invoice-date {
        text-align: right;
        padding-left: 20px;
      }

      .invoice-price {
        background: #f0f3f4;
        display: table;
        width: 100%;
      }

      .invoice-price .invoice-price-left,
      .invoice-price .invoice-price-right {
        display: table-cell;
        padding: 0px 15px;
        font-size: 20px;
        font-weight: 600;
        width: 75%;
        position: relative;
        vertical-align: middle;
      }

      .invoice-price .invoice-price-left .sub-price {
        display: table-cell;
        vertical-align: middle;
        padding: 0 20px;
      }

      .invoice-price small {
        font-size: 12px;
        font-weight: 400;
        display: block;
      }

      .invoice-price .invoice-price-row {
        display: table;
        float: left;
      }

      .invoice-price .invoice-price-right {
        width: 25%;
        background: #fff;
        color: #000000;
        font-size: 28px;
        text-align: right;
        font-weight: 450;
        border: 3px solid #000;
      }

      .invoice-price .invoice-price-right small {
        display: block;
        opacity: .6;
        position: absolute;
        top: 15px;
        left: 15px;
        font-size: 12px;
      }

      .invoice-footer,
      .invoice-footer-middle {
        border-top: 1px solid #ddd;
        padding-top: 20px;
        font-size: 20px;
      }

      .line-bar {
        border-top: 2px solid #000;
        padding-top: 10px;
        font-size: 15px;
      }

      .invoice-note {
        color: rgb(52, 0, 71);
        margin-top: 20px;
        font-size: 75%;
      }

      .invoice > div:not(.invoice-footer) {
        margin-bottom: 10px;
      }

      .btn.btn-white,
      .btn.btn-white.disabled,
      .btn.btn-white.disabled:focus,
      .btn.btn-white.disabled:hover,
      .btn.btn-white[disabled],
      .btn.btn-white[disabled]:focus,
      .btn.btn-white[disabled]:hover {
        color: #2d353c;
        background: #fff;
        border-color: #d9dfe3;
      }
    `,
        }}
      />

      <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet" />

      <div className="container">
        <div className="col-md-12">
          <div className="invoice">
            <div className="invoice-company text-inverse f-w-600">
              <div
                className="row"
                style={{ marginBottom: '0px', marginTop: '0px', paddingBottom: '0px', paddingTop: '0px' }}
              >
                <div className="col">
                  <img src="/img/asif/logo-inv.png" />
                </div>
                <div className="col">
                  <span className="pull-right">Invoice Id: {get(invoice, '_id', '').toUpperCase().substring(9)}</span>
                </div>
              </div>
            </div>
            <div className="invoice-header">
              <div className="invoice-from">
                <small>Institute</small>
                <address className="m-t-5 m-b-5">
                  <strong className="text-inverse">{get(institute, 'institutionName', '')}</strong>
                  <br />
                  {address.length > 0 ? address[0] : ''}
                  <br />
                  {address.length > 1 ? address[1] : ''}
                  <br />
                  {address.length > 2 ? address[2] : ''}
                  <br />
                  Phone: {institute.mobile}
                  <br />
                </address>
              </div>
              <div className="invoice-to">
                <small>Student Information</small>
                <address className="m-t-5 m-b-5">
                  <strong className="text-inverse">{get(student, 'username', '')}</strong>
                  <br />
                  <strong>Roll: </strong>
                  {get(student, 'roll', '')}
                  <br />
                  <strong>Session: </strong>
                  {get(student, 'session', '')}
                  <br />
                  <strong>Phone: </strong>
                  {get(student, 'phone', '')}
                  <br />
                  <strong>Institute: </strong>
                  {get(student, 'address', '')}
                </address>
              </div>
              <div className="invoice-date">
                <small>Invoice Details</small>
                <div className="invoice-detail">
                  <strong>Details: </strong>
                  {get(invoice, 'paymentDetails', '').substring(0, 75)}
                  <br />
                  {/* <strong>Payment Method: {get(invoice , 'paymentMethod' , '')}</strong><br /> */}
                  <strong>Print Date: </strong>
                  {new Date().toDateString()}
                  <br />
                  <strong>Received By: </strong>
                  {get(invoice, 'verifiedBy.phone', '')}
                  <br />
                  <strong>Time: </strong>
                  {get(invoice, 'collectionForMonth', '') +
                    ' - ' +
                    get(invoice, 'year', '') +
                    ' (' +
                    get(invoice, 'type', '') +
                    ')'}
                  <br />
                  <strong>Course: </strong>
                  {get(invoice, 'courseId.title', '')}
                  <br />
                  <strong>Total Due: </strong>
                  {due} BDT
                </div>
              </div>
            </div>
            <div className="invoice-content">
              <div className="invoice-price">
                <div className="invoice-price-left">
                  <div className="invoice-price-row">
                    <div className="sub-price">
                      <small>Payment Method</small>
                      <span className="text-inverse">{get(invoice, 'paymentMethod', '').toUpperCase()}</span>
                    </div>
                    <div className="sub-price">{/* <i className="fa fa-plus text-muted" /> */}</div>
                    <div className="sub-price">
                      <small>Payment Date</small>
                      <span className="text-inverse">{new Date(moment(get(invoice, 'createdAt'))).toDateString()}</span>
                    </div>
                    <div className="sub-price">
                      <small>Type</small>
                      <span className="text-inverse">Admin Copy</span>
                    </div>
                    <div className="sub-price">
                      <QRCode value={publicLink} bgColor={'#FFFFFF'} fgColor={'#000000'} size={110}></QRCode>
                    </div>
                  </div>
                </div>
                <div className="invoice-price-right">
                  TOTAL PAID <br />
                  <strong>
                    <span className="f-w-600">{get(invoice, 'amount', 0)} BDT</span>
                  </strong>
                </div>
              </div>
            </div>
            <div className="invoice-note">
              {config.INVOICE_FOOTER_NOTE}
              <br />
            </div>
            <div className="invoice-footer"></div>
            <br />
            <br />
            <div className="form-row" style={{ marginBottom: '0px' }}>
              <div className="form-group col-md-3">
                <div className="line-bar">Student Sign</div>
              </div>
              <div className="form-group col-md-3">
                <div className="line-bar">Guardian Sign</div>
              </div>
              <div className="form-group col-md-3">
                <div className="line-bar">Received By</div>
              </div>
              <div className="form-group col-md-3">
                <div className="line-bar">Authorised Sign</div>
              </div>
              {/*<span className="pull-right">*/}
              {/*  {get(institute, 'institutionName', '')}*/}
              {/*  <strong># Phone: {institute.mobile}</strong>*/}
              {/*</span>*/}
            </div>
          </div>
        </div>
      </div>

      {/*<br />*/}
      <div className="invoice-footer-middle"></div>
      {/*<br />*/}

      <div className="container">
        <div className="col-md-12">
          <div className="invoice">
            <div className="invoice-company text-inverse f-w-600">
              {/*<span className="pull-right hidden-print">*/}
              {/*  Invoice Id: {get(invoice, '_id', '').toUpperCase().substring(9)}*/}
              {/*</span>*/}
              {/*{get(institute, 'institutionName', '')} <strong># Phone: {institute.mobile}</strong>*/}
              <div
                className="row"
                style={{ marginBottom: '0px', marginTop: '0px', paddingBottom: '0px', paddingTop: '0px' }}
              >
                <div className="col">
                  <img src="/img/asif/logo-inv.png" />
                </div>
                <div className="col">
                  <span className="pull-right">Invoice Id: {get(invoice, '_id', '').toUpperCase().substring(9)}</span>
                </div>
              </div>
            </div>
            <div className="invoice-header">
              <div className="invoice-from">
                <small>Institute</small>
                <address className="m-t-5 m-b-5">
                  <strong className="text-inverse">{get(institute, 'institutionName', '')}</strong>
                  <br />
                  {address.length > 0 ? address[0] : ''}
                  <br />
                  {address.length > 1 ? address[1] : ''}
                  <br />
                  {address.length > 2 ? address[2] : ''}
                  <br />
                  Phone: {institute.mobile}
                  <br />
                </address>
              </div>
              <div className="invoice-to">
                <small>Student Information</small>
                <address className="m-t-5 m-b-5">
                  <strong className="text-inverse">{get(student, 'username', '')}</strong>
                  <br />
                  <strong>Roll: </strong>
                  {get(student, 'roll', '')}
                  <br />
                  <strong>Session: </strong>
                  {get(student, 'session', '')}
                  <br />
                  <strong>Phone: </strong>
                  {get(student, 'phone', '')}
                  <br />
                  <strong>Institute: </strong>
                  {get(student, 'address', '')}
                </address>
              </div>
              <div className="invoice-date">
                <small>Invoice Details</small>
                <div className="invoice-detail">
                  <strong>Details: </strong>
                  {get(invoice, 'paymentDetails', '').substring(0, 75)}
                  <br />
                  {/* <strong>Payment Method: {get(invoice , 'paymentMethod' , '')}</strong><br /> */}
                  <strong>Print Date: </strong>
                  {new Date().toDateString()}
                  <br />
                  <strong>Received By: </strong>
                  {get(invoice, 'verifiedBy.phone', '')}
                  <br />
                  <strong>Time: </strong>
                  {get(invoice, 'collectionForMonth', '') +
                    ' - ' +
                    get(invoice, 'year', '') +
                    ' (' +
                    get(invoice, 'type', '') +
                    ')'}
                  <br />
                  <strong>Course: </strong>
                  {get(invoice, 'courseId.title', '')}
                  <br />
                  <strong>Total Due: </strong>
                  {due} BDT
                </div>
              </div>
            </div>
            <div className="invoice-content">
              <div className="invoice-price">
                <div className="invoice-price-left">
                  <div className="invoice-price-row">
                    <div className="sub-price">
                      <small>Payment Method</small>
                      <span className="text-inverse">{get(invoice, 'paymentMethod', '').toUpperCase()}</span>
                    </div>
                    <div className="sub-price">{/* <i className="fa fa-plus text-muted" /> */}</div>
                    <div className="sub-price">
                      <small>Payment Date</small>
                      <span className="text-inverse">{new Date(moment(get(invoice, 'createdAt'))).toDateString()}</span>
                    </div>
                    <div className="sub-price">
                      <small>Type</small>
                      <span className="text-inverse">Student Copy</span>
                    </div>
                    <div className="sub-price">
                      <QRCode value={publicLink} bgColor={'#FFFFFF'} fgColor={'#000000'} size={110}></QRCode>
                    </div>
                  </div>
                </div>
                <div className="invoice-price-right">
                  TOTAL PAID <br />
                  <strong>
                    <span className="f-w-600">{get(invoice, 'amount', 0)} BDT</span>
                  </strong>
                </div>
              </div>
            </div>
            <div className="invoice-note">
              {config.INVOICE_FOOTER_NOTE}
              <br />
            </div>
            <div className="invoice-footer"></div>
            <br />
            <br />
            <div className="form-row" style={{ marginBottom: '0px' }}>
              <div className="form-group col-md-3">
                <div className="line-bar">Student Sign</div>
              </div>
              <div className="form-group col-md-3">
                <div className="line-bar">Guardian Sign</div>
              </div>
              <div className="form-group col-md-3">
                <div className="line-bar">Received By</div>
              </div>
              <div className="form-group col-md-3">
                <div className="line-bar">Authorised Sign</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
